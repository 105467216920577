<template>
  <div class="container">
    <div class="content">
      <div class="row" style="margin-top:-20px">
        <div class="col-xs-12 col-md-6 col-md-offset-3" style="text-align: center" v-if="!sendt">
          <h1 style="margin-bottom:20px">TA KONTAKT!</h1>
          <form @submit.prevent="sendEmail">
            <label id="namelabel">Navn</label>
            <input type="text" v-model="name" name="name" id="name" />

            <label>Telefonnummer</label>
            <input type="text" v-model="phone" name="phone" id="phone" />

            <label id="emaillabel"> E-postaddresse</label>
            <input type="text" v-model="email" name="email" id="email" />

            <h3 style="margin: 50px 0 10px 0">Min henvendelse</h3>

            <label>Emne</label>
            <input type="text" v-model="topic" name="topic" id="topic" />

            <label>Henvendelsen gjelder</label>
            <textarea rows="6" v-model="message" name="message" id="message"></textarea>

            Du kan kontakte Andreas direkte her, men det er mer sannsynlig at du får raskt svar om du kontakter en av kollegaene hans i VitenWahl AS<br />
            <a href="https://www.vitenwahl.no/" target="_blank">☞ www.vitenwahl.no</a><br /><br /><br />

            <input class="button large-btn" style="text-align: center" type="submit" value="Send forespørsel" />
          </form>
        </div>
        <div class="col-xs-12 col-md-6 col-md-offset-3" style="text-align: center" v-else>
          <h1 style="margin-bottom:20px">TAKK!</h1>
          Din melding er mottatt!<br />Du hører fra oss snart
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  props: ["id"],
  data: () => ({
    sendt: false,
    name: "",
    phone: "",
    email: "",
    topic: "",
    message: ""
  }),
  methods: {
    errorDisplay(value) {
      document.querySelector("#" + value).style.color = "red";
      document.querySelector("#" + value).innerHTML += " (påkrevd)";
    },
    sendEmail(e) {
      if (!this.name || !this.email) {
        !this.name ? this.errorDisplay("name") : null;
        !this.email ? this.errorDisplay("email") : null;
      } else {
        emailjs.sendForm("service_vhqq4ns", "template_9x9yexu", e.target, "user_YKb7VbAUBTZRfk1QfA3FH").then(
          result => {
            console.log("SUCCESS!", result.status, result.text);
          },
          error => {
            console.log("FAILED...", error);
          }
        );

        // Reset form field
        this.name = "";
        this.phone = "";
        this.email = "";
        this.message = "";
        this.sendt = true;
      }
    }
  },
  mounted() {
    if (this.id === "konferansier") {
      this.topic = "Andreas Wahl som konferansier";
    } else if (this.id === "energisk") {
      this.topic = "Energisk - et vitenshow om energi";
    } else if (this.id === "seoikult") {
      this.topic = "Se!Oi!Kult!";
    } else if (this.id === "vannvittig") {
      this.topic = "Vannvittig vitenskap";
    } else if (this.id === "universet") {
      this.topic = "Universet på 42 minutter";
    } else if (this.id === "joss") {
      this.topic = "Jøss";
    }
  }
};
</script>

<style lang="scss">
label,
input,
textarea {
  display: block;
  width: 100%;
  text-align: left;
}

input,
textarea {
  padding: 3px 5px;
  margin: 2px 0 16px 0;
  border-radius: 3px;
  border: 1px solid #006297;
  background-color: #f0f5f5;
}
</style>
